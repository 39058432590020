import {
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    VStack,
    Box,
    Text,
    HStack,
    Flex,
    useDisclosure,
    IconButton,
} from "@chakra-ui/react";
import ListItinerary from "./ListItinerary";
import MyBucketList from "../../pages/MyBucketList";
import ItineraryFooter from "./ItineraryFooter";
import { useState, useEffect, useContext } from "react";
import { useSearchParams,useNavigate } from "react-router-dom"; // Import for managing query parameters
import CustomModal from "../CustomModal";
import { AppContext } from "../../context/AppContext";
import useAxios from "../../hooks/useAxios";

const NavigationTabs = () => {
    const context: any = useContext(AppContext);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [tabIndex, setTabIndex] = useState(1); // State to track the active tab
    const [searchParams] = useSearchParams(); // React-router hook to get query params
    const [once, setOnce] = useState(1);
    const { setLikeEntities, setLikeSearches } = context;
    const { fetchData } = useAxios();
    const navigate = useNavigate();

    useEffect(() => {
        const getUserLikeSearches = async () => {
            try {
                const { data } = await fetchData({
                    method: "POST",
                    url: `user/getUserLikeSearches`,
                    options: {
                        data: { token: localStorage.getItem("token") },
                        headers: { "Content-Type": "application/json" },
                    },
                    auth: false,
                });

                const like_searches_data = data["data"];
                const like_searches = like_searches_data["like_searches"];
                setLikeSearches(like_searches);
                return like_searches;
            } catch (error) {
                console.error("Error fetching user like searches:", error);
                return [];
            }
        };

        const getUserLikeEntities = async () => {
            try {
                const { data } = await fetchData({
                    method: "POST",
                    url: `user/getUserLikeEntities`,
                    options: {
                        data: { token: localStorage.getItem("token") },
                        headers: { "Content-Type": "application/json" },
                    },
                    auth: false,
                });

                const like_entities_data = data["data"];
                const like_entities = like_entities_data["like_entities"];
                setLikeEntities(like_entities);
                return like_entities;
            } catch (error) {
                console.error("Error fetching user like entities:", error);
                return [];
            }
        };

        if (localStorage.getItem("token") && once) {
            getUserLikeSearches();
            getUserLikeEntities();
            setOnce(0);
            console.log("logging.....");
        }
    }, [fetchData, setLikeEntities, setLikeSearches, once]);

    // Effect to set the tab index based on query params
    useEffect(() => {
        const tab = searchParams.get("tab"); // Get the "tab" parameter from the URL
        if (tab === "bucketlists") {
            setTabIndex(1); // Show "Bucketlists" tab
        } else if (tab === "itineraries") {
            setTabIndex(2); // Show "Saved Itineraries" tab
            onOpen(); // Open modal when navigating directly to itineraries tab
        }
        else{
            setTabIndex(1);
        }
    }, [searchParams, onOpen]); // Runs when searchParams change

    const handleTabChange = (index: number) => {
        setTabIndex(index);
        if (index === 2) {
            onOpen(); // Open modal when "Saved Itineraries" tab is clicked
        }
        else{
            setTabIndex(1);
        }
    };

    const handleBackButton = () => {
      navigate(-1);
  };

    // Modified onClose to switch back to the "Bucketlists" tab
    const handleCloseModal = () => {
        onClose();
        setTabIndex(1); // Shift back to "Bucketlists" tab
    };

    return (
        <>
            <VStack bg="black" minH={"100dvh"} w={"100vw"} pt={2}>
                <Box
                    position={"fixed"}
                    top={0}
                    left={0}
                    w={"100vw"}
                    h={"50vh"}
                    zIndex={"2"}
                    bg={
                        "linear-gradient(180deg, #252128 0%, rgba(36, 29, 50, 0) 100%)"
                    }
                />
                <Tabs
                    isLazy
                    variant={"unstyled"}
                    zIndex={5}
                    index={tabIndex} // Set the active tab index
                    onChange={handleTabChange} // Handle tab change
                >
                    <TabList  w={"100vw"} >
                        <Tab p={'2px'}>
                            <HStack w={"100%"}>
                                <IconButton
                                    aria-label="Back"
                                    icon={
                                        <img
                                            src="/icons/Back_Icon_White.svg"
                                            alt="back"
                                        />
                                    }
                                    colorScheme="gray"
                                    size="lg"
                                    variant={"link"}
                                    onClick={handleBackButton}
                                />
                            </HStack>
                        </Tab>
                        <Tab
                            sx={{
                                borderBottom: "1px solid #FFFFFF21",
                                w: "100vw",
                            }}
                            _selected={{
                                borderBottom: "2px solid #FFFFFFED",
                            }}
                        >
                            <Flex direction="column" alignItems="flex-start">
                                <Text
                                    color="#FFFFFF54"
                                    fontFamily="Poppins"
                                    fontSize="14px"
                                    fontWeight="500"
                                    lineHeight="18.62px"
                                    letterSpacing="-0.5611507892608643px"
                                    textAlign="left"
                                >
                                    Your
                                </Text>
                                <Text
                                    fontFamily="Poppins"
                                    fontSize="14px"
                                    fontWeight="700"
                                    lineHeight="18.62px"
                                    letterSpacing="-0.5611507892608643px"
                                    textAlign="left"
                                    as="span"
                                    color="#FFFFFFED"
                                >
                                    Bucketlists
                                </Text>
                            </Flex>
                        </Tab>
                        <Tab
                            isFocusable={true}
                            sx={{
                                borderBottom: "1px solid #FFFFFF21",
                                w: "100vw",
                            }}
                            _selected={{
                                borderBottom: "2px solid #FFFFFFED", // Bold border under the text
                            }}
                        >
                            <Flex direction="column" alignItems="flex-start">
                                <Text
                                    color="#FFFFFF54"
                                    fontFamily="Poppins"
                                    fontSize="14px"
                                    fontWeight="500"
                                    lineHeight="18.62px"
                                    letterSpacing="-0.5611507892608643px"
                                    textAlign="left"
                                >
                                    Saved
                                </Text>
                                <Text
                                    fontFamily="Poppins"
                                    fontSize="14px"
                                    fontWeight="700"
                                    lineHeight="18.62px"
                                    letterSpacing="-0.5611507892608643px"
                                    textAlign="left"
                                    as="span"
                                    color="#FFFFFFED"
                                >
                                    Itineraries
                                </Text>
                            </Flex>
                        </Tab>
                    </TabList>

                    <TabPanels>
                    <TabPanel>

                    </TabPanel>
                        <TabPanel>
                            <MyBucketList />
                        </TabPanel>

                        {/* Panel for Saved Itineraries */}
                        <TabPanel>
                            <ListItinerary />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
                <Box mt={20} />
            </VStack>
            <ItineraryFooter />

            {/* Modal */}
            <CustomModal isOpen={isOpen} onClose={handleCloseModal} />
        </>
    );
};

export default NavigationTabs;
