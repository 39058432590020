import React from "react";
import "@fontsource/sacramento";
import { Image } from "@chakra-ui/react";
import { Box, Text, VStack } from "@chakra-ui/react";

// Define types for the props
interface ResortCardProps {
    title?: string;
    subtitle?: string;
    imageSrc?: string;
    question1?: string;
    question2?: string;
}

const ResortCard: React.FC<ResortCardProps> = ({
    title,
    subtitle,
    imageSrc,
    question1,
    question2,
}) => {
    return (
        <VStack
            bg={"#16161A"}
            border="2px solid"
            sx={{
                borderImageSource: `radial-gradient(72.95% 72.95% at 48% 0%, rgba(255, 255, 255, 0.93) 0%, rgba(11, 11, 11, 0) 100%),linear-gradient(180deg, rgba(102, 102, 102, 0.33) 0%, rgba(107, 107, 107, 0.33) 100%)`,
                borderImageSlice: 1,
            }}
            borderRadius={'24px'}
            minW={"250px"}
            minH={"min-content"}
            pt={"5%"}
            pb={"7%"}
            mx={"15%"}
        >
            {/* Top Section */}
            <VStack spacing={0}>
                <Text
                    color="#FFFFFF54"
                    fontFamily={"sacramento"}
                    fontSize={["150%","190%","220%"]}
                    fontWeight={400}
                    letterSpacing={"-0.63px"}
                >
                    {subtitle}
                </Text>
                <Text
                    color={"#FFFFFF"}
                    textAlign={"center"}
                    fontSize={["150%","190%","220%"]}
                    fontWeight={900}
                    mt={"-4px"}
                    zIndex={1}
                    lineHeight={"90%"}
                >
                    {(() => {
                        const words = title?.split(" ") || [];
                        const midPoint = Math.ceil(words.length / 2);

                        return (
                            <>
                                {words.slice(0, midPoint).join(" ")} <br /> 
                                {words.slice(midPoint).join(" ")} 
                            </>
                        );
                    })()}
                </Text>

                <Image boxSize={"50%"} src={imageSrc} alt="Icon" />
            </VStack>

            {/* Middle Section - Questions */}
            <VStack spacing={0} mt={"7px"} mx={'9px'}>
                <Text fontSize={["11px", "17px","21px"]} fontWeight={"500"} color={"#FFFFFFA1"}>
                    Try asking
                </Text>
                <Box bg={"#F9FAFC12"} px={"16px"} borderRadius={"8px"} py={"6px"} mt={'4px'} fontSize={["12px","16px","20px"]} fontWeight={500}>
                    <Text color={"#FFFFFFA1"} letterSpacing={"-0.5px"}>{question1}</Text>
                </Box>
                <Box bg={"#F9FAFC12"} px={"16px"} borderRadius={"8px"} py={"6px"} mt={'6px'} fontSize={["12px","16px","20px"]} fontWeight={500}>
                    <Text color={"#FFFFFFA1"}>{question2}</Text>
                </Box>
            </VStack>

            {/* Bottom Section - Button */}
            {/* <Button
                fontSize={['15px',"19px","23px"]}
                mt={'4%'}
                w={'45%'}

                py={"20px"}
                bg={"linear-gradient(90deg, #ACAFEB 0%, #E9E1CD 100%)"}
                borderRadius={'100px'}
            >
                Start
            </Button> */}
        </VStack>
    );
};

export default ResortCard;
