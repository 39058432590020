import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../context/AppContext';
import { VideoHighlights } from './InfluencerFlow/VideoHighlights';

const SavedPlaces = ({data}) => {
	const context = useContext(AppContext);
	const { likeEntities } = context;

	const [savedPlaces, setSavedPlaces] = useState([]);
	useEffect(() => {
		const tempSavedPlaces = [];
		for (let entity of likeEntities) {
			if (
				entity['country'] === data[0] &&
				(entity['city'] === undefined ? 'Other' : entity['city']) ===
					data[1]
			) {
				tempSavedPlaces.push(entity);
			}
		}
		setSavedPlaces(tempSavedPlaces);
		
	}, [likeEntities, data]);

	return (
		// <Grid templateColumns='repeat(2, 1fr)' gap={12}>
		// 	{savedPlaces.map((place, index) => {
		// 		return (
		// 			<SavedPlacesCard
		// 				index={index}
		// 				previewImage={place.previewImage}
		// 				title={place.title}
		// 				description={place.description}
		// 				vibe={place.tags}
		// 				city={place.city}
		// 			/>
		// 		);
		// 	})}
		// </Grid>
		<VideoHighlights highlights={savedPlaces}/>
	);
};

export default SavedPlaces;
