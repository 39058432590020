import { Box, VStack, useDisclosure } from "@chakra-ui/react";
import Header from "./Header";
import TabNavigation from "./TabNavigation";
import ModalWithBackdrop from "../ModalWithBackdrop"; // Import the modal with backdrop
import SigninComponent from "../SigninComponent"; // Import the signin component
import useAuthRouteProtector from "../../hooks/useAuthRouteProtector";

const LandingPage: React.FC = () => {
  const { isAuthenticated } = useAuthRouteProtector();
  // Modal control for the Signin modal
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      {/* Signin Modal */}
      <ModalWithBackdrop isOpen={isOpen} onClose={onClose}>
        <SigninComponent onClose={onClose} />
      </ModalWithBackdrop>

      {/* Main page content */}
      <VStack
        bg="black"
        h={"100%"}
        w={"100%"}
        overflowX={"hidden"}
        overflowY={"auto"}
        minH={"100dvh"}
      >
        <Box
          position={"fixed"}
          top={0}
          left={0}
          w={"100vw"}
          h={"80vh"}
          zIndex={1}
          bg={"linear-gradient(180deg, #252128 0%, rgba(36, 29, 50, 0) 100%)"}
        />
        {/* Header: Fixed position at the top */}
        <Box
          position="fixed"
          top={0}
          left={0}
          w="100vw"
          zIndex={10} // Keep the z-index high to be on top
        >
          <Header onOpenLogin={onOpen} isAuthenticated={isAuthenticated} />
        </Box>

        {/* Main content below the header */}
        <VStack zIndex={5} w="100%" spacing={3} pt="19%">
          {" "}
          {/* Adjust padding-top */}
          {/* Padding added to prevent overlap with fixed Header */}
          <TabNavigation />
        </VStack>
      </VStack>
    </>
  );
};

export default LandingPage;
