// Loader.tsx
import { Spinner,  VStack, Text } from "@chakra-ui/react";
import React from "react";

const Loader: React.FC = () => (
    <VStack w={"100vw"} h={"100vh"} position={'fixed'}  bg={'black'} zIndex={999999999999999} alignItems={'center'} justifyContent={'center'}>
        <VStack w={'70%'} spacing={'20px'}>
            
            <Spinner size="xl" color="rgba(255, 255, 255, 0.40)"/>
            <Text
                color="rgba(255, 255, 255, 0.40)"
                textAlign="center"
                fontFamily="Poppins"
                fontSize="16px"
                fontStyle="normal"
                fontWeight="500"
                lineHeight="133%"
            >
                 
                 Curating your next adventure... Hang tight!
            </Text>
        </VStack>
    </VStack>
);

export default Loader;
