import React, { useState } from 'react';
import {
	Container,
	Box,
	Stack,
	FormControl,
	FormHelperText,
	PinInput,
	PinInputField,
	useToast,
} from '@chakra-ui/react';
import useAxios from '../hooks/useAxios';
import { useNavigate } from 'react-router-dom';
import TrPrimaryButton from './TrPrimaryButton';

const VerifyCode: React.FC<{
	onVerifySuccess?: (token: string) => void;
	onFail?: () => void;
	phone_number: string;
}> = ({ onVerifySuccess, onFail, phone_number }) => {
	const { fetchData, loading } = useAxios();
	const [pin, setPin] = useState<string>('');
	const [errors, setErrors] = useState<any>({});
	const navigate = useNavigate();
	const toast = useToast();

	const setPinValue = (value: string) => {
		setPin(value);
		setErrors({});
	};

	const sendVerifyRequest = async () => {
		if (!pin || pin.length < 6) {
			setErrors({ pin: 'PIN is required' });
			return;
		}

		const { data, error, statusCode } = await fetchData({
			method: 'POST',
			url: `login/phone/verify`,
			options: {
				data: { phone_number: phone_number, verification_code: pin },
				headers: { 'Content-Type': 'application/json' },
			},
			auth: false,
		});

		if (statusCode === 200) {
			setErrors({});
			toast({
				title: 'Success',
				description: 'Verification successful',
				status: 'success',
				duration: 3000,
				isClosable: true,
			});
			if (onVerifySuccess) {
				onVerifySuccess(data?.token);
			} else {
				navigate('/');
			}
		} else {
			setErrors({ server: error?.error || 'An error occurred' });
			if (onFail) {
				onFail();
			}
		}
	};

	return (
		<Container maxW='lg'>
			<Box p={6} py={8} bg='white' borderRadius='xl'>
				<Stack spacing='6'>
					<FormControl colorScheme='red' isInvalid={!!errors.pin}>
						<PinInput
							colorScheme='red'
							otp
							size={['md', 'md', 'lg']}
							onChange={setPinValue}
							value={pin}>
							{[...Array(6)].map((_, index) => (
								<PinInputField mx={['1px', '1px', '2px']} color='trPrimary' key={index} />
							))}
						</PinInput>
						<FormHelperText color='red'>{errors.pin}</FormHelperText>
					</FormControl>
					<TrPrimaryButton onClick={sendVerifyRequest} fontSize={'18px'} isLoading={loading}>
						Verify
					</TrPrimaryButton>
				</Stack>
			</Box>
		</Container>
	);
};

export default VerifyCode;
