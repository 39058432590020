import axios, { AxiosError } from 'axios';


const apiUrl = process.env.REACT_APP_API_URL;
const conversationalApi = 'https://conversation-memory-10363995693.us-central1.run.app/';


export const fetchChatResponse = async (
    query: string,
    setChatEntries: Function,
    dateTime: string,
    setIsLoading: Function,
    chatEntries: any[],  // Pass in the existing chat entries here
    userDetail:any
) => {
    setIsLoading(true);
    // console.log("query " + query);
    // console.log("query " + dateTime);

    // Transform chatEntries into the expected history format


    try {
        const history = [];

        for (let i = 0; i < chatEntries.length; i += 2) {
            const humanEntry = chatEntries[i]; // The human message
            const serverEntry = chatEntries[i + 1]; // The server/assistant response

            history.push({
                query: humanEntry.message, // Human message
                completion: [
                    {
                        answer: serverEntry.message, // Server response message
                        entities: serverEntry.entities || []  // Server response entities, if any
                    }
                ]
            });
        }
        // console.log("history is");
        // console.log(JSON.stringify(history));
        const response = await axios.post(`${apiUrl}v1/conversation/1234/query`, {
            query: {title: query},
            context:{
                user:{
                    details:userDetail.traveler_details
                }
            },
            chat_history: history  // Pass the formatted history
        }, {
            headers: {
                'Content-Type': 'application/json',
            }
        });

        const data = response.data.data;
        // console.log("response is ", JSON.stringify(data));
        const htmlString = data.answer;

        setChatEntries((prevEntries: any) => {
            const newEntries = [...prevEntries];
            newEntries[newEntries.length - 1] = {
                role: "assistant",
                type: data.entities ? "TextR" : "Text",
                message: htmlString ? htmlString : "Something went wrong. Please try again.",
                entities: data.entities || null,
                time: dateTime
            };
            return newEntries;
        });
        
        

        console.log('Success:', data);
    } catch (error) {
        const axiosError = error as AxiosError;
        console.error('Fetch error:', axiosError);
        if (axiosError.response) {
            console.error('Error Response Body:', axiosError.response.data);
        }
    } finally {
        setIsLoading(false);
    }
};


// Database insertion
export const insertIntoDatabase = async (format: any) => {
    try {
        console.log("format" + format);
        const response = await axios.post(conversationalApi + 'insert_into_database', JSON.stringify(format), {
            headers: {
                'Content-Type': 'application/json',
            }
        });

        console.log(response);

    } catch (error) {
        const axiosError = error as AxiosError;
        console.error('Fetch error:', axiosError);
        if (axiosError.response) {
            console.error('Error Response Body:', axiosError.response.data);
        }
    } finally {
    }
};

export const fetchConversation = async (
    conversation_id: string,
    token: string,
    setChatEntries: Function
) => {
    try {
        const response = await axios.post(conversationalApi + 'get_conversation_by_user_id_and_conversation_id', {
            conversation_id: conversation_id,
            token: token
        }, {
            headers: {
                'Content-Type': 'application/json',
            }
        });

        const content = response.data.content;
        // console.log("response is ");
        // console.log(JSON.stringify(response));

        if (Array.isArray(content)) {
            // Format the data as per the structure expected by setChatEntries
            const formattedEntries = content.map(entry => ({
                message: entry.message,
                role: entry.role,
                time: entry.time,
                type: entry.type,
                entities: entry.entities || [],
                onBehalf:entry.onBehalf??undefined
                

            }));

            // Update the chat entries state
            setChatEntries(formattedEntries);
        }

    } catch (error) {
        const axiosError = error as AxiosError;
        console.error('Fetch error:', axiosError);
        if (axiosError.response) {
            console.error('Error Response Body:', axiosError.response.data);
        }
    }
};

// FETCH STORY DETAIL
export const fetchEntityDetails = async (
    title: string,
    userDetail:any,
    context?:any
) => {
    try {
        console.log('userDetail ', userDetail);
        const response = await axios.post(`${apiUrl}v1/entity`, {
            query: {
                title: title,
                context: context??{}
            },
            context:{
                user:{
                    details:userDetail.traveler_details
                }
            }
        }, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return response.data;

    } catch (error) {
        const axiosError = error as AxiosError;
        console.error('Fetch error:', axiosError);
        if (axiosError.response) {
            console.error('Error Response Body:', axiosError.response.data);
        }
    } finally {
        // Any cleanup code if needed
    }
};
