import React from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
} from '@chakra-ui/react';

type BackdropModalProps = {
	isOpen: boolean;
	onClose: () => void;
	children: React.ReactNode;
	title?: string;
	isCentered?: boolean;
};
const ModalWithBackdrop: React.FC<BackdropModalProps> = ({
	isOpen,
	onClose,
	children,
	title,
	isCentered = true,
}) => {
	const modelOverlay = (
		<ModalOverlay
			bg='blackAlpha.300'
			backdropFilter='blur(10px) hue-rotate(90deg)'
		/>
	);

	return (
		<Modal isCentered={isCentered} isOpen={isOpen} onClose={onClose}>
			{modelOverlay}
			<ModalContent
				overflow={'auto'}
				background={'#fff'}
				borderRadius={{ base: 0, lg: '34px' }}
				m={0}
				w='100%'
				maxW={{ base: '100%', lg: 'max-content' }}
				height={{ base: '100%', lg: 'max-content' }}
				p={0}>
				{title ? <ModalHeader>{title}</ModalHeader> : ''}
				<ModalCloseButton zIndex={5} onClick={onClose} color="gray.400"/>
				<ModalBody w='100%' m={0} p={0}>
					{children}
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default ModalWithBackdrop;
