import { Box, Image, VStack, Text } from '@chakra-ui/react';
import SearchBar from '../SearchComponents/SearchBar';
import { useEffect } from 'react';
// import { AppContext } from '../../context/AppContext';
// import useAxios from '../../hooks/useAxios';
import { useLocation, useNavigate } from 'react-router-dom';

export const SearchSection: React.FC = () => {
	const navigate = useNavigate();
	const location = useLocation();
	// const context: any = useContext(AppContext);
	// const {

	// 	setLikeStatus,
	// 	likeEntities,
	// 	likeSearches,
	// } = context;

	// const initializeLikeStatus = async (videos: any[], city: string) => {
	// 	interface Video {
	// 		id: number;
	// 		liked: boolean;
	// 		highLights: boolean[];
	// 	}

	// 	let tempLikeStatus: Video[] = [];
	// 	let index = 0;

	// 	for (let i = 0; i < videos.length; i++) {
	// 		let search_status = false;

	// 		for (let k = 0; k < likeSearches.length; k++) {
	// 			if (videos[i]['url'] === likeSearches[k]['url']) {
	// 				search_status = true;
	// 				break;
	// 			}
	// 		}
	// 		let video: Video = {
	// 			id: index,
	// 			liked: search_status,
	// 			highLights: [],
	// 		};

	// 		for (let j = 0; j < videos[i].highlights.length; j++) {
	// 			let check = true;
	// 			for (let k = 0; k < likeEntities.length; k++) {
	// 				if (
	// 					likeEntities[k]['city'] === city &&
	// 					likeEntities[k]['title'] === videos[i]['highlights'][j]['title']
	// 				) {
	// 					check = false;
	// 					break;
	// 				}
	// 			}

	// 			if (check) {
	// 				video.highLights.push(false);
	// 			} else {
	// 				video.highLights.push(true);
	// 			}
	// 		}
	// 		tempLikeStatus.push(video);
	// 		index++;
	// 	}
	// 	setLikeStatus(tempLikeStatus);
	// };

	const handleSearchInputBlur = async (query: any) => {
		 localStorage.setItem('query', query);
		if (location.pathname === '/home') {
			return navigate(`/home/search?query=${query}`);
		}
	};

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const queryParam = searchParams.get('query');
		if (queryParam) {
			handleSearchInputBlur(queryParam);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.search]);
	return (
		<Box>
			<SearchBar onSearch={(value?: any) => handleSearchInputBlur(value)} />
			<Box display='flex' justifyContent='center' alignItems='center' mt={28}>
				{' '}
				{/* Adjusting for SearchBar height */}
				<VStack w={'219.72px'} spacing={'20px'}>
					<Image src='/icons/SearchLight.svg' alt='Search Light' />
					<Text
						color='rgba(255, 255, 255, 0.23)'
						textAlign='center'
						fontFamily='Poppins'
						fontSize='14px'
						fontStyle='normal'
						fontWeight='500'
						lineHeight='133%'>
						Smash the search bar for some really really cool videos!
					</Text>
				</VStack>
			</Box>
		</Box>
	);
};
