import { useCallback, useContext, useState } from 'react';
import { AppContext } from '../context/AppContext';
import useAxios from '../hooks/useAxios';
import { pixels } from '../theme';
import { Flex, Image, useDisclosure, Text, Button } from '@chakra-ui/react';
import TrCircleButton from './TrCircleButton';
import ModalWithBackdrop from './ModalWithBackdrop';
import { useNavigate } from 'react-router-dom';
import SigninComponent from './SigninComponent';

const LikeAndShare = () => {
	const {
		searchDetail,
		currentVideoIndex,
		likeStatus,
		setLikeStatus,
		shareModal,
		likeSearches,
		setLikeSearches,
	}: any = useContext(AppContext);
	const { fetchData } = useAxios();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const navigate = useNavigate();
	const myAppUrl = process.env.REACT_APP_URL;
	const [showAddedToBucket, setShowAddedToBucket] = useState(false);

	const likeSearch = useCallback(async () => {
		const token = localStorage.getItem('token');
		console.log(likeStatus);
		if (token) {
			const data = searchDetail['videos'];
			const body = {
				title: data[currentVideoIndex]?.title,
				city:
					data[currentVideoIndex]?.city === ''
						? undefined
						: data[currentVideoIndex]?.city,
				countries:
					data[currentVideoIndex]?.country??{},
				description: data[currentVideoIndex]?.description,
				tags: data[currentVideoIndex]?.tags,
				url: data[currentVideoIndex]?.video_url,
				previewImage: data[currentVideoIndex]?.preview_image,
				location: data?.location,
				flightStartingPrice: data[currentVideoIndex]?.flightStartingPrice,
			};

			if (!likeStatus[currentVideoIndex]?.liked) {
				await fetchData({
					method: 'POST',
					url: 'user/likeSearch',
					options: {
						data: { ...body, token },
						headers: { 'Content-Type': 'application/json' },
					},
					auth: false,
				});

				const obj = likeStatus;
				obj[currentVideoIndex].liked = true;
				// setLikeStatus(obj);
				setLikeStatus((prevStatus: any) => {
					const updatedStatus = { ...prevStatus };
					updatedStatus[currentVideoIndex].liked = true;
					return updatedStatus;
				});

				setLikeSearches([...likeSearches, body]);
				setShowAddedToBucket(true);

				setTimeout(() => {
					setShowAddedToBucket(false);
				}, 3000);
			} else {
				await fetchData({
					method: 'POST',
					url: 'user/dislikeSearch',
					options: {
						data: { ...body, token },
						headers: { 'Content-Type': 'application/json' },
					},
					auth: false,
				});

				const obj = likeStatus;
				console.log(currentVideoIndex);
				obj[currentVideoIndex].liked = false;
				// setLikeStatus(obj);
				setLikeStatus((prevStatus: any) => {
					const updatedStatus = { ...prevStatus };
					updatedStatus[currentVideoIndex].liked = false;
					return updatedStatus;
				});

				const tempLikeSearches = likeSearches.filter(
					(search: any) =>
						search['url'] !== data[currentVideoIndex]?.['video_url'],
				);
				console.log('this is your seraches ; ', likeSearches);
				console.log('this is your temp like Searches ; ', tempLikeSearches);
				setLikeSearches(tempLikeSearches);
			}
		} else {
			onOpen();
		}
	}, [
		searchDetail,
		currentVideoIndex,
		likeStatus,
		likeSearches,
		fetchData,
		setLikeStatus,
		setLikeSearches,
		onOpen,
	]);

	const handleShareClick = useCallback(
		(title?: any, url?: any) => {
			shareModal.handleShareClick(encodeURI(url), title);
		},
		[shareModal],
	);

	return (
		<>
			<Flex gap={pixels['12pixels']}>
				<ModalWithBackdrop
					isOpen={isOpen}
					onClose={() => {
						onClose();
						navigate('/home');
					}}>
					<SigninComponent onClose={onClose} />
				</ModalWithBackdrop>
				<TrCircleButton
					size={'32px'}
					sx={{
						height: {
							base: '32px !important',
							lg: '32px !important',
						},
						width: {
							base: '32px !important',
							lg: '32px !important',
						},
					}}
					aria-label='like'
					onClick={(e) => {
						e.stopPropagation();
						likeSearch();
					}}>
					{likeStatus[currentVideoIndex]?.liked === true ? (
						<Image
							sx={{
								h: { base: '15px', lg: '15px' },
								w: { base: '15px', lg: '15px' },
							}}
							src='/icons/like.svg'
							alt='like'
						/>
					) : (
						<Image
							sx={{
								h: { base: '15px', lg: '15px' },
								w: { base: '15px', lg: '15px' },
							}}
							src='/icons/heart-white.svg'
							alt='like'
						/>
					)}
				</TrCircleButton>
				<TrCircleButton
					size={'32px'}
					sx={{
						height: {
							base: '32px !important',
							lg: '32px !important',
						},
						width: {
							base: '32px !important',
							lg: '32px !important',
						},
					}}
					onClick={(e) => {
						console.log('app url', myAppUrl);
						e.stopPropagation();
						const title = searchDetail?.videos[currentVideoIndex]?.title
							? searchDetail?.videos[currentVideoIndex]?.title
							: searchDetail?.videos[currentVideoIndex]?.video_play_url?.title;
						const link = `${myAppUrl}/home/video-detail?link=${searchDetail?.videos[currentVideoIndex]?.video_url}`;
						console.log('link', link);

						handleShareClick(title, link);
					}}
					aria-label='share'>
					<Image
						sx={{
							h: { base: '15px', lg: '15px' },
							w: { base: '15px', lg: '15px' },
						}}
						src='/icons/share2.svg'
						alt='share'
					/>
				</TrCircleButton>
			</Flex>
			{showAddedToBucket && (
				<Flex
					position='fixed'
					bottom='10px'
					left='50%'
					transform='translateX(-50%)'
					bg='#AE7CFF'
					w={'300px'}
					h={'40px'}
					color='white'
					borderRadius='8px'
					alignItems={'center'}
					justifyContent={'space-around'}
					zIndex={200}>
					<Text
						fontFamily='Poppins'
						fontSize='18px'
						fontWeight={'medium'}
						lineHeight='19px'
						letterSpacing='-0.5611507892608643px'
						textAlign='left'>
						Added to bucket list
					</Text>
					<Button
						variant={'ghost'}
						color={'white'}
						_hover={{ bg: 'none' }}
						fontWeight={'medium'}
						onClick={() => navigate('/home/navigate')}>
						View
					</Button>
				</Flex>
			)}
		</>
	);
};

export default LikeAndShare;
