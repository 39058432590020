import React, {
  lazy,
  Suspense,
  useState,
  useEffect,
  useContext,
  useRef,
} from "react";
import {
  Box,
  Flex,
  Text,
  Slide,
  Skeleton,
  HStack,
  IconButton,
  Image,
  useDisclosure,
} from "@chakra-ui/react";
import { keyframes } from "@emotion/react";
import { useSwipeable } from "react-swipeable";
import ReactPlayer from "react-player";
import Header from "./Header";
import PlacesTabs from "./PlaceTabs";
import useAxios from "../../hooks/useAxios";
import { useLocation } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { fetchEntityDetails } from "../../services/apiService";
import { ToastBucketList } from "../ToastBucketList";
import ModalWithBackdrop from "../ModalWithBackdrop";
import SigninComponent from "../SigninComponent";

interface Story {
  title: string;
  city: string;
  country: string;
  continent: string;
  location: {
    lat: number;
    long: number;
  };
  description: string;
  image?: string[];
  videos?: string[];
  tags: string[];
  otherDetail?: any[];
}

const defaultStories: Story[] = [
  {
    title: "",
    city: "",
    country: "",
    continent: "",
    location: { lat: 0, long: 0 },
    description: "",
    tags: ["", ""],
    image: [""],
  },
];

const moveUpDown = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0); }
`;

const LocationDetail = lazy(() => import("./LocationDetail"));

const StoryCarousel: React.FC = () => {
  const context: any = useContext(AppContext);
  const { fetchData } = useAxios();
  const { likeEntities, setLikeEntities, entitesDetail, setEntitesDetail, influencerData, setInfluencerData, setStoryState, storyState, userDetail } =
    context;
  const [storyIndex, setStoryIndex] = useState(0);
  const [mediaIndex, setMediaIndex] = useState(0);
  const [showNewPage, setShowNewPage] = useState(false);
  const [stories, setStories] = useState<Story[]>(defaultStories);
  const [key, setKey] = useState(0);
  const [isLiked, setIsLiked] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showAddedToBucket, setShowAddedToBucket] = useState(false);


  const location = useLocation();
  const initialEntityDetail = location.state?.initialEntityDetail ?? storyState?.initialEntityDetail;
  const contxt = location.state?.context ?? {};
  const str = location.state?.stories ?? undefined

  useEffect(() => {
    if (!storyState && location.state)
      setStoryState(location.state);
    console.log("storyState, ", storyState);
    // eslint-disable-next-line
  }, [location.state])

  // eslint-disable-next-line
  const allEntities = location.state?.allEntities ?? storyState?.allEntities ?? [{ title: "", image: "" }];
  const [loadingStates, setLoadingStates] = useState<boolean[]>([]);

  const likeButtonRef = useRef<HTMLButtonElement>(null);
  const shareButtonRef = useRef<HTMLButtonElement>(null);

  const currentStory = stories[storyIndex];
  const isVideoStory = currentStory?.videos !== undefined;
  const isImageStory = currentStory?.image !== undefined;
  const currentMedia = isVideoStory
    ? currentStory?.videos![mediaIndex]
    : isImageStory
      ? currentStory?.image![mediaIndex]
      : undefined;

  const handleNextMedia = () => {
    if (isVideoStory && currentStory.videos!.length - 1 > mediaIndex) {
      setMediaIndex(mediaIndex + 1);
    } else if (isImageStory && currentStory.image!.length - 1 > mediaIndex) {
      setMediaIndex(mediaIndex + 1);
    } else {
      handleNextStory();
    }
  };

  const handleNextStory = () => {
    if (storyIndex < stories.length - 1) {
      setStoryIndex(storyIndex + 1);
      setMediaIndex(0);
    }
  };

  const handlePreviousStory = () => {
    if (storyIndex > 0) {
      setStoryIndex(storyIndex - 1);
      setMediaIndex(0);
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: (eventData) => {
      if (Math.abs(eventData.deltaX) > 50) { // Customize sensitivity here
        handleNextStory();
      }
    },
    onSwipedRight: (eventData) => {
      if (Math.abs(eventData.deltaX) > 50) { // Customize sensitivity here
        handlePreviousStory();
      }
    },
    onSwipedUp: (eventData) => {
      if (Math.abs(eventData.deltaY) > 50) { // Customize sensitivity here
        setShowNewPage(true);
      }
    },
    onTap: (event) => {
      // Access native event's target
      const target = event.event.target as Node;

      // Prevent onTap if the target is the like or share button
      if (
        (likeButtonRef.current && likeButtonRef.current.contains(target)) ||
        (shareButtonRef.current && shareButtonRef.current.contains(target))
      ) {
        return;
      }
      handleNextMedia();
    },
    // trackMouse: false,
    delta: 50, // Minimum swipe distance to trigger an action
    preventScrollOnSwipe: true, // Prevent scrolling while swiping
  });


  const handleTabClick = (index: number) => {
    setStoryIndex(index);
    setMediaIndex(0);
  };

  useEffect(() => {
    const initialIndex = location.state?.clickedIndex ?? 0;
    if (!str) {
      if (initialEntityDetail && allEntities) {

        const newLoadingStates = Array(allEntities.length).fill(true);
        newLoadingStates[initialIndex] = false;
        setLoadingStates(newLoadingStates);

        const formatStory = (entityDetail: any) => ({
          title: entityDetail.title,
          city: entityDetail.city,
          country: entityDetail.country,
          continent: entityDetail.continent,
          location: entityDetail.location,
          description: entityDetail.description,
          image: [entityDetail.image],
          tags: entityDetail.tags,
          otherDetail: entityDetail.dynamic_fields,
        });

        // console.log("allEntities ", allEntities);
        // console.log("initialEntityDetail ", JSON.stringify(initialEntityDetail.entity));

        console.log("initialIndex ", initialIndex);
        setStoryIndex(initialIndex);

        const initialStory = formatStory(initialEntityDetail);
        let newStories = Array(allEntities?.length).fill(defaultStories);
        newStories[initialIndex] = initialStory;
        setStories(newStories);

        const fetchOtherTitles = async () => {
          console.log("infetch detail");
          const currDetail = Array(allEntities?.length).fill(defaultStories);
          const updatedStories = [...stories];
          try {
            const fetchPromises = allEntities?.map(
              (entity: { title: string }, index: number) => {
                // Check if the current story already has a title
                if (stories[index]?.title === initialEntityDetail.title) {
                  return Promise.resolve(stories[index]); // Already loaded, resolve immediately
                } else {
                  // Initiate the API call for the current entity's title
                  return fetchEntityDetails(entity?.title, userDetail, contxt)
                    .then((response) => {
                      if (response.status === 200) {
                        // If the response is successful, format the story data
                        const formattedStory = formatStory(response.data.entity);
                        // Update the corresponding index in the copied stories array
                        updatedStories[index] = formattedStory;
                        currDetail[index] = formattedStory;
                        setStories([...updatedStories]);
                        setEntitesDetail(currDetail);

                        setLoadingStates((prevLoadingStates) =>
                          prevLoadingStates.map((state, i) =>
                            i === index ? false : state
                          )
                        );
                        console.log("index fetched: ", index);
                      }
                    })
                    .catch(() => {
                      // In case of an error during the API call, handle it here
                      // Optional: add a fallback or default story data if needed
                    });
                }
              }
            );

            // Execute all promises independently and log the result
            Promise.allSettled(fetchPromises).then((results) => {
              console.log("All fetch operations have settled:", results);
            });
          } catch (error) {
            console.log(error);
          }
        };

        const curr = entitesDetail;

        if (curr.length > 0) {
          console.log("entity detail is ", entitesDetail);
          console.log("entity detail iieoeoons ", curr);



          // If all titles match, update the stories
          setStories(entitesDetail);
          setLoadingStates(Array(allEntities.length).fill(false));

        }
        else {
          setEntitesDetail([]);
          console.log("entity detail in else is ", entitesDetail);
          console.log("entity detail iieoeoons ", curr);

          fetchOtherTitles();
        }
      }
    }
    else {
      setStories(str);
    }
    // eslint-disable-next-line
  }, [allEntities, initialEntityDetail]);

  useEffect(() => {

    const fetchVideos = async () => {
      if (!allEntities || allEntities.length === 0) return;

      // Initialize the state with an empty 2D array
      setInfluencerData(Array(allEntities.length).fill([]));

      allEntities.forEach(async (entity: { title: any; }, index: number | number) => {
        try {
          const body = {
            query: {
              title: entity?.title,
              context: contxt ?? {}
            },
            context: {
              user: {
                details: userDetail.traveler_details
              }
            }
          }

          const { data } = await fetchData({
            method: 'POST',
            url: "v1/video-highlights",
            options: {
              data: { ...body },
              headers: { 'Content-Type': 'application/json' },
            },
            auth: false,
          });

          // Update state incrementally as each API call completes
          setInfluencerData((prev: any[][]) => {
            const updatedData = [...prev];
            updatedData[index] = data?.videos || [];
            return updatedData;
          });
        } catch (error) {
          console.error(`Error fetching videos for ${entity?.title}:`, error);
        }
      });
    };
    console.log("influencerData  ", influencerData);
    if (influencerData.length === 0) {
      console.log("inside if ");
      fetchVideos();
    }
    // eslint-disable-next-line
  }, [allEntities]);

  useEffect(() => {
    setKey((prevKey) => prevKey + 1);
  }, [storyIndex]);

  useEffect(() => {
    document.body.style.overflow = showNewPage ? "hidden" : "auto";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showNewPage]);

  useEffect(() => {
    // console.log("check??");
    setIsLiked(false);
    for (let entity of likeEntities) {
      if (entity["title"] === currentStory?.title) {
        setIsLiked(true);
      }
    }
  }, [likeEntities, currentStory]);

  const handleLike = async () => {
    const token = localStorage.getItem("token");
    if (token && currentStory) {
      // console.log(props)
      const body = {
        image: currentStory?.image?.[0],
        city: currentStory?.city,
        country: currentStory?.country,
        title: currentStory?.title,
        tags: currentStory?.tags,
        description: currentStory?.description,
      };
      // console.log("body ", body);

      if (!isLiked) {
        await fetchData({
          method: "POST",
          url: "user/likeEntity",
          options: {
            data: { ...body, token: localStorage.getItem("token") },
            headers: { "Content-Type": "application/json" },
          },
          auth: false,
        });

        setLikeEntities([...likeEntities, body]);

        setShowAddedToBucket(true);

        setTimeout(() => {
          setShowAddedToBucket(false);
        }, 3000);
      } else {
        await fetchData({
          method: "POST",
          url: "user/dislikeEntity",
          options: {
            data: { ...body, token: localStorage.getItem("token") },
            headers: { "Content-Type": "application/json" },
          },
          auth: false,
        });

        const tempLikeEntities = [];
        for (let entity of likeEntities) {
          if (!(entity["title"] === body?.title)) {
            tempLikeEntities.push(entity);
          }
        }
        setLikeEntities(tempLikeEntities);
      }
    }
  };
  const handleLikeButton = (e: React.MouseEvent) => {
    e.stopPropagation(); // Stop the event from bubbling up
    const token = localStorage.getItem("token");
    if (!token) {
      onOpen();
    } else {
      setIsLiked(!isLiked);
      handleLike();
    }
  };
  const handleShare = () => {
    console.log("share button clicked");
  };

  return (
    <>
      <Box
        width="100%"
        height="100vh"
        overflowY="auto"
        position="relative"
        bg="#040D10"
        color="white"
      >
        <Header />
        <PlacesTabs
          storyIndex={storyIndex}
          onTabClick={handleTabClick}
          allEntities={allEntities}
        />

        {/* Fullscreen overlay to prevent interactions when LocationDetail is open */}

        {showNewPage && (
          <Box
            position="fixed"
            top={0}
            left={0}
            width="100%"
            height="100%"
            bg="blackAlpha.700"
            zIndex={10}
            onClick={(e) => e.stopPropagation()} // Prevent clicks from reaching underlying elements
          />
        )}

        <Box
          {...(!showNewPage ? handlers : {})}
          position="relative"
          cursor="pointer"
          my={0}
          mx={4}
          width="92vw"
          height="80vh"
          borderRadius="12px"
          overflow="hidden"
          zIndex={showNewPage ? 0 : 1} // Ensure zIndex order
        >
          {showAddedToBucket && (
            <ToastBucketList />
          )}
          <Skeleton
            isLoaded={!loadingStates[storyIndex]} // Show skeleton while loading is true
            width="100%"
            height="100%"
          >
            {isVideoStory ? (
              <Box
                position="absolute"
                top={0}
                left={0}
                right={0}
                bottom={0}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <ReactPlayer
                  url={currentMedia}
                  playing={true}
                  muted={false}
                  loop={false}
                  controls={false}
                  onEnded={handleNextMedia}
                  width="100%"
                  height="100%"
                  style={{ position: "absolute", top: 0, left: 0 }}
                  config={{
                    file: {
                      attributes: {
                        style: {
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        },
                      },
                    },
                  }}
                />
              </Box>
            ) : (
              isImageStory && (
                <Image
                  src={currentMedia}
                  alt={currentStory?.title}
                  width="100%"
                  height="100%"
                  objectFit="cover"
                />
              )
            )}

            <Box position="absolute" top="10px" right="10px" zIndex={100}>
              <HStack spacing={0} justifyContent="flex-end">
                <IconButton
                  aria-label="Like"
                  ref={likeButtonRef}
                  icon={
                    <Image
                      src={isLiked ? "/icons/like.svg" : "/icons/white-heart.svg"}
                      alt="like"
                    />
                  }
                  variant="ghost"
                  size="lg"
                  onClick={handleLikeButton}
                  borderRadius="full"
                  dropShadow="0px 4px 10px rgba(0, 0, 0, 0.6)" // Customize shadow here
                  _hover={{ bg: "transparent" }}
                  _active={{ bg: "transparent" }}
                  _focus={{ boxShadow: "none" }}
                />
                <IconButton
                  aria-label="Share"
                  ref={shareButtonRef}
                  icon={<Image src="/icons/white-share.svg" alt="share" />}
                  variant="ghost"
                  onClick={handleShare}
                  size="lg"
                  borderRadius="full"
                  _hover={{ bg: "transparent" }}
                  _active={{ bg: "transparent" }}
                  _focus={{ boxShadow: "none" }}
                />
              </HStack>
            </Box>

            {(isVideoStory || isImageStory) && (
              <Flex
                position="absolute"
                bottom="35%"
                left="50%"
                transform="translate(-50%, 35%)"
                justifyContent="center"
                alignItems="center"
                zIndex={2}
              >
                {(isVideoStory ? currentStory.videos : currentStory.image)!.map(
                  (_, index) => (
                    <Box
                      key={index}
                      width="8px"
                      height="8px"
                      mx={1}
                      borderRadius="50%"
                      backgroundColor={mediaIndex === index ? "white" : "gray"}
                      opacity={mediaIndex === index ? 1 : 0.5}
                      transition="opacity 0.3s ease"
                    />
                  )
                )}
              </Flex>
            )}

            <Box
              position="absolute"
              bottom={0}
              width="100%"
              p={4}
              fontFamily="Poppins"
              bgGradient="linear(to-t, blackAlpha.800, transparent)"
            >
              <Text
                fontSize="26.54px"
                fontWeight="500"
                lineHeight="29.66px"
                letterSpacing="-1.0290316343307495px"
                bgGradient="linear-gradient(180deg, rgba(255, 255, 255, 0.93) 53.49%, rgba(192, 192, 192, 0.93) 95.69%)"
                bgClip="text"
                textAlign="left"
              >
                {currentStory?.title}
              </Text>
              <Text
                fontSize="13px"
                fontWeight="500"
                lineHeight="16.95px"
                letterSpacing="0.3px"
                textAlign="left"
              >
                {currentStory?.city}
              </Text>
              <Box zIndex={3} borderRadius="12px">
                <Text
                  fontSize="14px"
                  fontWeight="500"
                  mt={10}
                  lineHeight="19px"
                  noOfLines={3}
                  letterSpacing="-0.7px"
                  textAlign="justify"
                  bgGradient="linear-gradient(180deg, #FFFFFF 14.74%, rgba(15, 15, 15, 0) 119.41%)"
                  bgClip="text"
                >
                  {currentStory?.description}
                </Text>
              </Box>
              <HStack
                spacing={0}
                position={"relative"}
                zIndex={11}
                justifyContent={"center"}
                animation={`${moveUpDown} 1s infinite`}
              >
                <Text fontSize="13px" color="gray.400" fontWeight="300">
                  {"SWIPE UP FOR MORE "}
                </Text>

                <Image src="/icons/ArrowUp.svg" alt="swipe up" />
              </HStack>
            </Box>
          </Skeleton>
        </Box>

        <Slide direction="bottom" in={showNewPage} style={{ zIndex: 11 }}>
          <Box p={4} bg="#000000" h="100dvh" overflowY="auto">
            <Suspense fallback={<Skeleton height="100vh" width="100vw" />}>
              <LocationDetail
                key={key}
                handleBackButton={() => setShowNewPage(false)}
                story={currentStory}
                influencerData={influencerData[storyIndex]}
              />
            </Suspense>
          </Box>
        </Slide>
      </Box>
      <ModalWithBackdrop isOpen={isOpen} onClose={onClose}>
        <SigninComponent onClose={onClose} />
      </ModalWithBackdrop>
    </>
  );
};

export default StoryCarousel;
