import { Box, SimpleGrid } from "@chakra-ui/react";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import DestinationCard from "../components/Collection-flow/DestinationCard";

import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../context/AppContext";
import useAxios from "../hooks/useAxios";

interface allEntity{
    title: string;
    image: string;
}
interface InputEntity{
    title: string;
    image: string;
    entity_detail: object;
    search_detail: object;
  };

  interface Story {
    title: string;
    city: string;
    country: string;
    continent: string;
    location: {
      lat: number;
      long: number;
    };
    description: string;
    image?: string[];
    videos?: string[];
    tags: string[];
    otherDetail?: any[];
  }

const destinations = [
    {
        imageSrc: "/images/Iceland.png",
        subheadingText: "Northern lights spots",
        headingText: "Iceland",
        query: "Show me best spots to view Northern Lights in Iceland"
    },
    {
        imageSrc: "/images/Greece.png",
        subheadingText: "Best Christmas markets in",
        headingText: "Europe",
        query: "Best Christmas markets in Europe this winter"
    },
    {
        imageSrc: "/images/Nightlife.png",
        subheadingText: "Best of London",
        headingText: "Night Life",
        query: "Best clubs and places to experience nightlife in London"
    },
    {
        imageSrc: "/images/Asia.png",
        subheadingText: "Luxury resorts in",
        headingText: "North India",
        query: "Find me the 6 most bespoke, hidden luxury resorts in North India"
    },
   
    {
        imageSrc: "/images/Switzerland.png",
        subheadingText: "Enchanting",
        headingText: "Switzerland",
        query: "Tell me the best romantic spots in Switzerland to experience during the winters"
    },
    {
        imageSrc: "/images/Japan.png",
        subheadingText: "Best of",
        headingText: "Japan",
        query: "Best ways to experience cultural immersion in Japan"
    }
];


const Collection: React.FC = () => {
    const navigate = useNavigate();
    const context: any = useContext(AppContext);
    const { setIsCollection, setInfluencerData } = context;
    const { fetchData } = useAxios();

    const convertToStories = (data: any[]): Story[] => {
        return data.map(item => ({
          title: item.entity_detail.title,
          city: item.entity_detail.city,
          country: item.entity_detail.country,
          continent: item.entity_detail.continent,
          location: item.entity_detail?.location ?? { lat: 0, long: 0 },
          description: item.entity_detail.description,
          image: item.entity_detail.image ? [item.entity_detail.image] : [""],
          videos:undefined,
          tags: item.entity_detail.tags,
          otherDetail: item.entity_detail.dynamic_fields
        }));
      };
      

    const handleCollectionCard =(destination: any) => {
        setIsCollection(true);
        const fetchCollection = async () => {
            const { data } = await fetchData({
                method: "GET",
                url: "v1/collection/"+destination.query,
                options: {
                    data: {},
                    headers: { "Content-Type": "application/json" },
                },
                auth: false,
            });
            if(data){
                const allEntities: allEntity[] = data.map(({ title, image }: InputEntity) => ({ title, image }));
                const stories = convertToStories(data);
                const videos = data.map((item: { search_detail: { videos: any } }) => item.search_detail?.videos || []);
                await setInfluencerData(videos);
                console.log("infl ",videos);
                navigate("/home/story-carousel", {
                    state: {
                      clickedIndex: 0,
                      stories:stories,
                      allEntities: allEntities, // pass the array with title and image
                    },
                  });

                

            }
        }
        fetchCollection();
    }

    return (
        <Box
            minH="100vh"
            w="100vw"
            overflow="hidden">
            <Box
                position="relative"
                zIndex={1}
                px="12px">
                <Box mb="20%">
                    <SimpleGrid columns={2} spacing={3} alignItems={'center'} justifyContent={'center'} >
                        {destinations.map((destination, index) => (
                            <DestinationCard
                                key={index}
                                onClick={()=>handleCollectionCard(destination)}
                                imageSrc={destination.imageSrc}
                                headingText={destination.headingText}
                                subheadingText={destination.subheadingText}
                            />
                        ))}
                    </SimpleGrid>

                </Box>
            </Box>
        </Box>

    );
}

export default Collection;