import { Flex, Button, Text } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"


export const ToastBucketList: React.FC = () => {
    const navigate = useNavigate();
    return (
        <Flex
            position="fixed"
            bottom="10px"
            left="50%"
            transform="translateX(-50%)"
            bg="#AE7CFF"
            w={"auto%"}
            minW={"max-content"}
            h={"40px"}
            p={1}
            color="white"
            borderRadius="8px"
            alignItems={"center"}
            justifyContent={"space-evenly"}
            zIndex={200}
        >
            <Text
                fontFamily="Poppins"
                fontSize="18px"
                fontWeight={"sm"}
                ml={3}
                lineHeight="19px"
                letterSpacing="-0.5611507892608643px"
                textAlign="left"
            >
                Added to bucket list
            </Text>
            <Button
                variant={"ghost"}
                color={"white"}
                _hover={{ bg: "none" }}
                fontFamily="Poppins"
                fontSize="18px"
                fontWeight={"medium"}
                lineHeight="19px"
                letterSpacing="-0.5611507892608643px"
                textAlign="center"
                onClick={() => navigate("/home/navigate")}
            >
                View
            </Button>
        </Flex>
    )
}