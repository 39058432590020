import { HStack, IconButton, Image } from "@chakra-ui/react";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";

const Header: React.FC = () => {
  const navigate = useNavigate();
  const previousLocationRef = useRef(null);

  return (
    <HStack
      justifyContent="space-between"
      p={[4, 6, 8]}  // Responsive padding for mobile, tablet, and desktop
      spacing={[1, 2, 4]}  // Responsive spacing between HStack elements
    >
      <HStack>
        <IconButton
          aria-label="Go Back"
          icon={
            <Image
              src="/icons/Back_Icon_White.svg"
              alt="back"
              w={'200%'}
            />
          }
          variant="ghost"
          onClick={() => {
            const prevLocation = previousLocationRef.current;
            console.log('previous location ', prevLocation);
            // if (prevLocation?.includes("influencer")) {
            //   navigate('/home/chat'); // Navigate to /home/chat
            // } else {
            navigate(-1); // Go back to the last page
            // }
          }}
          bg="#FFFFFF08"
          size={["sm", "md", "lg"]}  // Adjust button size for different screens
          borderRadius="100px"
        />
      </HStack>

    </HStack>
  );
};

export default Header;
